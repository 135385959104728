import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import Helmet from "react-helmet";

export default function Contact () {  
  const title = "Contacto";
  return (
    <AnimationRevealPage>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Contacto renta de oficinas" />
      </Helmet>
      <ContactUsForm/>
    </AnimationRevealPage>
  );
}