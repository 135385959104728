import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import HonestyIcon from "../../images/icons/honesty.svg";
import ThumbsIcon from "../../images/icons/thumbsup.svg";
import StarIcon from "../../images/icons/star.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;


export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: HonestyIcon,
      title: "Honestidad",
      description: "Ten la seguridad de que el espacio que te proporcionamos es el más adecuado, cómodo y perfecto para impulsar tu empresa.",
      altText: "honestidad"
    },
    {
      imageSrc: ThumbsIcon, 
      title: "Experiencia",
      description: "Siempre actualizados en trámites y necesidades que requieran el marco jurídico.",
      altText: "habitacional"
    },
    {
      imageSrc: StarIcon,
      title: "Responsabilidad",
      description: "Tenemos el compromiso de satisfacer las necesidades de nuestros clientes con un servicio de manera personalizada.",
      altText: "remodelarciones"
    }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading><span tw="text-primary-500">Nuestros Valores</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt={card.altText} />
              </span>
              <span className="textContainer">
                <h4 className="title">{card.title || "Fully Secure"}</h4>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
