import React, {useEffect, useContext, useState} from "react";
import UserContext from '../context/UserContext';
import { useHistory } from 'react-router-dom';
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { Subheading } from "components/misc/Headings.js";
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import Drag from 'components/deel/Drag';

const FormContainer = tw.div`w-full mt-8 max-w-md`;
const Heading = tw(Subheading)`mt-4 font-black text-center text-xl sm:text-xl lg:text-xl text-center md:text-center leading-tight w-full`;
const Container = tw.div`w-full bg-gray-200 flex justify-center overflow-hidden`;
const Input = tw.input`w-full py-2 px-3 shadow rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white first:mt-0`;
const Label = tw.label`block text-gray-700 text-sm font-bold mt-3`;
const TextArea = tw.textarea`w-full py-2 px-3 shadow rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white first:mt-0`;
const Formm = tw(Form)`mt-8 mb-8`;
const ErrorLabel = tw.p`text-red-500 text-xs italic`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export default function AddOffice () {
  const { userData }                  = useContext(UserContext);
  const history                       = useHistory();
  const [title, setTitle]             = useState('');
  const [size, setSize]               = useState('');
  const [description, setDescription] = useState('');
  const [rentPrice, setRentPrice]     = useState('');
  const [salePrice, setSalePrice]     = useState('');
  const [type, setType]               = useState('');
  const [floor, setFloor]             = useState('');
  const [address, setAddress]         = useState('');
  const [place, setPlace]             = useState('');
  const [files, setFiles]             = useState('');

  useEffect(() =>{
    // if (!userData.user) {
    //   history.push("/login");
    // }
  });

  function resetStates () {
    setTitle('');
    setSize('');
    setDescription('');
    setRentPrice('');
    setSalePrice('');
    setType('');
    setFloor('');
    setAddress('');
  }

  function onDrop (files) {
    console.log(files);
    setFiles(files);
  }

  function uploadImages (id) {
    var formData = new FormData();
    var images = [];
    files.map((file, index) => {
      formData.append('images', file);
    });
    formData.append('id', id);
    return fetch(window.URL + "offices/addImages", {
      method: 'POST',
      body: formData
    });
  }

  return (
    <Container>
      <FormContainer>
        <Heading>Agregar Oficina</Heading>
        <Formik
          enableReinitialize
          initialValues={{
            title:       title,
            size:        size,
            description: description,
            rentPrice:   rentPrice,
            salePrice:   salePrice,
            type:        type,
            floor:       floor,
            place:       place,
            address:     address,
          }}
          validationSchema={Yup.object({
            title: Yup.string()
              .required("Requerido")
              .min(12, "El texto es muy corto mínimo 12 carácteres"),
            size: Yup.string()
              .required("Requerido")
              .matches(/^[0-9]*$/, "Debe ser un número"),
            description: Yup.string()
              .required("Requerido")
              .min(20, "El texto es muy corto"),
            type: Yup.string()
              .required("Requerido"),
            floor: Yup.string()
              .required("Requerido")
          })}
          onSubmit={(values, {resetForm}) => {
            console.log("onSubmit: ", values, files);
            axios.post(window.URL+ "offices/add", values).then(function(response) {
              console.log(response);
              uploadImages(response.data.insertId).then(function(response) {
                console.log(response);
                resetStates();
              }, function(error) {
                console.error(error);
              });
            }, function(error) {
              console.error(error);
            })
          }}
        >
          {props => (
            <Formm>
              <Label>Título (Ingresa el título de la publicación)</Label>
              <Input type="text" placeholder="Título" name="title" value={title} onChange={(e)=>{setTitle(e.target.value)}}/>
              {props.errors.title && <ErrorLabel>{props.errors.title}</ErrorLabel>}
              <Label>Tamaño de la oficina</Label>
              <Input type="text" placeholder="Tamaño" name="size" value={size} onChange={(e)=>{setSize(e.target.value)}}/>
              {props.errors.size && <ErrorLabel>{props.errors.size}</ErrorLabel>}
              <Label>Descripción (Ingresa una descripción de la oficina lo mas especifica y clara posible)</Label>
              <TextArea type="text" placeholder="Descripción" name="description" value={description} onChange={(e)=>{setDescription(e.target.value)}}/>
              {props.errors.description && <ErrorLabel>{props.errors.description}</ErrorLabel>}
              <Label>Precio renta</Label>
              <Input type="text" placeholder="Precio renta" name="rentPrice" value={rentPrice} onChange={(e)=>{setRentPrice(e.target.value)}}/>
              {props.errors.rentPrice && <ErrorLabel>{props.errors.rentPrice}</ErrorLabel>}
              <Label>Precio venta</Label>
              <Input type="text" placeholder="Precio venta" name="salePrice" value={salePrice} onChange={(e)=>{setSalePrice(e.target.value)}}/>
              {props.errors.salePrice && <ErrorLabel>{props.errors.salePrice}</ErrorLabel>}
              <Label>Tipo operación (venta y/o renta)</Label>
              <Input type="text" placeholder="Tipo operación" name="type" value={type} onChange={(e)=>{setType(e.target.value)}}/>
              {props.errors.type && <ErrorLabel>{props.errors.type}</ErrorLabel>}
              <Label>Piso (número de piso o planta baja)</Label>
              <Input type="text" placeholder="Piso" name="floor" value={floor} onChange={(e)=>{setFloor(e.target.value)}}/>
              {props.errors.floor && <ErrorLabel>{props.errors.floor}</ErrorLabel>}
              <Label>Lugar (Ej: World Trade Center)</Label>
              <Input type="text" placeholder="Lugar" name="place" value={place} onChange={(e)=>{setPlace(e.target.value)}}/>
              {props.errors.place && <ErrorLabel>{props.errors.place}</ErrorLabel>}
              <Label>Dirección</Label>
              <TextArea type="text" placeholder="Dirección" name="address" value={address} onChange={(e)=>{setAddress(e.target.value)}}/>
              {props.errors.address && <ErrorLabel>{props.errors.address}</ErrorLabel>}
              <Label>Imágenes</Label>
              <Drag onDrop={onDrop}/>
              <SubmitButton type="submit">
                <span className="text">Enviar</span>
              </SubmitButton>
            </Formm>
          )}
        </Formik>
      </FormContainer>
    </Container>
  );
}