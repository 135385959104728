import React from "react";
import ConstructionImage from "images/deel/construction.png";
import styled from "styled-components";
import tw from "twin.macro";
import { motion } from "framer-motion";

const Container = styled.div`
  ${tw`container mx-auto flex justify-center relative h-screen min-h-144 w-full bg-cover bg-center`}`;

const Image = styled(motion.div)(props => [
  `background-image: url("${ConstructionImage}");`,
  tw`h-64 bg-cover bg-center rounded h-screen`
]);

export default function Working () {
  return (
    <Image>
    </Image>
  );
}