import React, {useState, useContext} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import logo from "images/logo.svg";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import UserContext from '../context/UserContext';
import { useHistory } from 'react-router-dom';


const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const FForm = tw(Form)`mx-auto max-w-xs`;
const Input = tw(Field)`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

const ErrorLabel = tw.p`text-red-500 text-xs italic`;

export default function Login ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Inicia sesión",
  submitButtonText = "Iniciar sesión",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "#",
}){
  const [error, setError]       = useState('');
  const [email, setEmail]       = useState('');
  const [password, setPassword] = useState('');
  const { setUserData } = useContext(UserContext);
  const history = useHistory();

  return (
  <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          {/*<LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>*/}
          <MainContent>
            <Heading>{headingText}</Heading>
            {error && <ErrorLabel>{error}</ErrorLabel>}
            <FormContainer>
              <Formik
                enableReinitialize
                initialValues={{
                  email: email,
                  password: password,
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .required("Requerido")
                    .email("No es un e-mail valido"),
                  password: Yup.string()
                    .required("Requerido")
                    .min(5, "Debe ingresar 5 caracteres mínimo")
                })}
                onSubmit={(values) => {
                  var url = window.URL + "/users/login";
                  axios.post(url, values).then(function(response) {
                    setUserData({
                      token: response.data.token,
                      user: response.data.user.name
                    });
                    localStorage.setItem("auth-token", response.data.token);
                    history.push("/agregaroficina");
                  }, function(error) {
                    console.error(error);
                  });
                }}
              >{props => (
                <FForm>
                  <Input type="text" placeholder="Correo Electrónico" name="email" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                  {props.errors.email && <ErrorLabel>{props.errors.email}</ErrorLabel>}
                  <Input type="password" placeholder="Contraseña" name="password" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                  {props.errors.password && <ErrorLabel className="text-red-500 text-xs italic">{props.errors.password}</ErrorLabel>}
                  <SubmitButton type="submit">
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>
                </FForm>
              )}
              </Formik>
              <p tw="mt-6 text-xs text-gray-600 text-center">
                <a href={forgotPasswordUrl} tw="border-b border-gray-500 border-dotted">
                  Forgot Password ?
                </a>
              </p>
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer>
      </Content>
    </Container>
  </AnimationRevealPage>
)};
