import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/deel/contact.jpg";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const FForm = tw(Form)`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8 text-lg`

export default function Contact({
  subheading = "Contáctanos",
  heading = <>¿En qué te podemos <span tw="text-primary-500">ayudar</span>?</>,
  description = "Recibe atención personalizada, déjanos un mensaje y en breve nos pondremos en contacto contigo.",
  submitButtonText = "Enviar",
  textOnLeft = true,
}) {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Formik
              initialValues={{
                email: 'asd',
                name: '',
                phone: '',
                subject: '',
                message: 'fda',
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email("No es un e-mail valido")
                  .required("Requerido"),
                name: Yup.string()
                  .min(3, "Debe ingresar 3 caracteres mínimo")
                  .required("Requerido"),
                message: Yup.string()
                  .min(10, "Debe ingresar 3 caracteres mínimo")
                  .required("Requerido"),
                subject: Yup.string()
                  .min(3, "Debe ingresar 3 caracteres mínimo")
                  .required("Requerido"),
                phone: Yup.string()
              })}
              onSubmit={(values, {setSubmitting, resetForm}) => {
                console.log("Here?");
                axios.post();
              }}
            >
              {({ errors, touched, isValidating }) => (
                <FForm>
                  <Input type="email" name="email" placeholder="Tu e-mail"/>
                  {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
                  <Input type="text" name="name" placeholder="Tu nombre" />
                  {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
                  <Input type="text" name="phone" placeholder="Tu teléfono (opcional)" />
                  {errors.phone && <p className="text-red-500 text-xs italic">{errors.phone}</p>}
                  <Input type="text" name="subject" placeholder="Asunto" />
                  {errors.subject && <p className="text-red-500 text-xs italic">{errors.subject}</p>}
                  <Textarea type="text" name="message" placeholder="Tu mensaje" />
                  {errors.message && <p className="text-red-500 text-xs italic">{errors.message}</p>}
                  <SubmitButton type="submit">{submitButtonText}</SubmitButton>
                </FForm>
              )}
            </Formik>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
