import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import axios from 'axios';
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";


const Container = styled.div`
    ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-auto m-3 flex flex-col items-center lg:flex-row flex-wrap`}`;
const Card = tw.div`h-full lg:w-1/3 max-w-xs my-4 mx-2 lg:items-stretch lg:flex-row flex-wrap sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;
const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

export default function Offices(){
 useEffect(function() {
  const getData = async function() {
    await axios.post(window.URL + "offices/getLastOffices").then(function(response) {
      setCards(response.data.offices);
      console.log("---set cards response---");
    });
  }
  getData();
  }, []);
  const [cards, setCards] = useState([]);
  return (
      <Container>
        {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.path} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{card.locationText}</Text>
                  </IconWithText>
                  <IconWithText>
                    <IconContainer>
                      <PriceIcon />
                    </IconContainer>
                    <Text>{card.pricingText}</Text>
                  </IconWithText>
                </SecondaryInfoContainer>
                <Description>{card.description}</Description>
              </TextInfo>
              <PrimaryButton href={`/oficina-renta-wtc-${card.officeId}`}>Mas información</PrimaryButton>
            </Card>
          ))}
      </Container>
  );
}