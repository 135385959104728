import React from "react"
import Hero from "components/hero/BackgroundAsImageWithCenteredContent"
import Features from "components/features/DashedBorderSixFeatures";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import MainFeature from "components/features/TwoColWithButton.js";
import CardsContainer from "components/deel/CardsContainer.js";
import office from "images/deel/wtc-mexico.jpg";
//Deel2020__
export default function Home () {
    return (
      <AnimationRevealPage>
        <Hero/>
        <Features/>
        <CardsContainer/>
        <MainFeature
          subheading=""
          heading="Renta y Venta de Oficinas"
          description="Estamos actualizados en los temas jurídicos necesarios para brindarte un servicio integral. Contamos con oficinas físicas desde 40m2 hasta 110 m2."
          imageSrc={office}
          buttonRounded={false}
          primaryButtonText="Conócelas"
        />
      </AnimationRevealPage>
    );
}