import React, {useEffect, useContext} from "react"
import tw from "twin.macro";
import Header, { NavLink, NavLinks, PrimaryLink} from "components/headers/light.js";
import UserContext from 'context/UserContext';


const HeroContainer = tw.div`z-20 max-w-none w-full p-4`;

const navLinks = [
  <NavLinks key={1}>
    <NavLink href="/oficinas-renta">
      Renta
    </NavLink>
    <NavLink href="/oficinas-venta">
      Venta
    </NavLink>
    <NavLink href="/remodelaciones">
      Remodelaciones
    </NavLink>
    <NavLink href="/nosotros">
      Nosotros
    </NavLink>
  </NavLinks>,
  <NavLinks key={2}>
    <PrimaryLink href="/contacto">
      Contactanos
    </PrimaryLink>
  </NavLinks>
];

export default function DeelHeader () {
  const { userData } = useContext(UserContext);

  useEffect(() =>{
    // if (userData.user) {
    //   console.log(navLinks[0]);
    // }
  });

  return (
    <HeroContainer>
      <Header links={navLinks}></Header>
    </HeroContainer>
  );
}