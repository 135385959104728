import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Header from "components/forms/TwoColContactUsWithIllustration";
import HeaderImage from "images/deel/desktop.jpg";
import Features from "components/features/ColumnsAbout";
import MainFeature from "components/features/TwoCol.js";
import visionImage from "images/deel/visionimage.jpg";
import missionImage from "images/deel/missionimage.jpg";
import Helmet from "react-helmet";

const title = "Acerca de Nostros";
const vision = <span>Trabajamos día a día dando consultoría a interesados en el mundo inmobiliario, desde el cuidado de  inmuebles de nuestros clientes propietarios hasta buscar proporcionar un servicio excepcional para encontrar el espacio deseado por los inquilinos, nuestro interés es que toda la información que requieren este en sus manos, de forma precisa y eficaz.<br/><br/> Poder empatizar con las necesidades ambos clientes para agilizar los trámites y poder llegar a acuerdos profesionales, seguros y prontos.</span>;
const mission = <span>Nuestro interés principal es poder brindarte la atención que mereces en la venta y renta de oficinas, locales, bodegas y cajones de estacionamiento. Darle la confianza a los propietarios al saber que su patrimonio está en manos de profesionales y expertos en el área de bienes raíces.<br/><br/> Nos interesa escuchar las necesidades de nuestros clientes y ya sea que contemos con el espacio que requiere o le apoyamos en la búsqueda necesaria para su comodidad.</span>;

export default function About () {
  return (
    <AnimationRevealPage>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header 
        subheading="Deel Bienes Raíces"
        heading="Sobre Nosotros"
        image = {HeaderImage}
        description = "Bienes raíces, renta y venta de inmuebles en la Ciudad de México; son nuestros servicios que por más de 20 años han brindado garantía,satisfacción y comodidad a cada uno de nuestros clientes-inquilinos."
      />
      <Features/>
      <MainFeature
        subheading=""
        heading="Nuestra Visión"
        description={vision}
        imageSrc={visionImage}
      />
      <MainFeature
        subheading=""
        heading="Nuestra Misión"
        description={mission}
        imageSrc={missionImage}
        textOnLeft={false}
      />
    </AnimationRevealPage>
    );
}