import React, {useState, useEffect} from "react"
import "App.css"
import "tailwindcss/dist/base.css"
import Home from "pages/Home";
import Login from "pages/Login";
import Working from "pages/Working";
import Office from "pages/Office";
import Offices from "pages/Offices";
import Nosotros from "pages/About";
import Contact from "pages/Contact";
import AddOffice from "pages/AddOffice";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Header from "components/headers/deel";
import Footer from "components/footers/deel";
import Axios from "axios";
import UserContext from "context/UserContext";

function App() {
  const [userData, setUserData] = useState({
    token: null,
    user:  null,
  });

  useEffect(() => {
    const checkLoggedIn = async () => {
      let token = localStorage.getItem("auth-token");
      if (token === null) {
        localStorage.setItem("auth-token", "");
        token = "";
      }
      const tokenRes = await Axios.post(
        window.URL + "/users/tokenIsValid",
        null,
        { headers: { "x-auth-token": token } }
      );
      if (tokenRes.data) {
        const userRes = await Axios.get(window.URL + "/users/", {
          headers: { "x-auth-token": token },
        });
        setUserData({
          token,
          user: userRes.data,
        });
      }
    };

    checkLoggedIn();
  }, []);

  return (
    <>
      <UserContext.Provider value={{ userData, setUserData }}>
        <Header />
      </UserContext.Provider>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/nosotros" component={Nosotros} />
          <Route exact path="/remodelaciones" component={Working} />
          <Route exact path="/contacto" component={Contact} />
          <Route exact path="/oficinas" component={Home} />
          <Route exact path="/oficinas-renta" component={Offices} />
          <Route exact path="/oficinas-venta" component={Offices} />
          <Route exact path="/oficina-renta-wtc-:id" component={Office} />
          <UserContext.Provider value={{ userData, setUserData }}>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/agregaroficina" component={AddOffice}/>
          </UserContext.Provider>
        </Switch>
      </Router>
      <Footer/>
    </>
  )
}

export default App