import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import styled from "styled-components";
import tw from "twin.macro";
import {useParams} from "react-router-dom";
import Helmet from "react-helmet";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import axios from 'axios';

import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as HomeIcon } from "feather-icons/dist/icons/home.svg";

export default function Office () {
  let { id } = useParams();
  const [office, setOffice] = useState({});

  useEffect(function() {
    const getData = async function() {
      await axios.post(window.URL + "offices/getOfficeById", {id: id}).then(function(response) {
        setOffice(response.data.office);
      });
    }
    getData();
  }, []);

  const Container = styled.div`
    ${tw`relative -mx-8 -mt-8 bg-center bg-cover max-h-screen`}`;

  const DataContainer = tw.div`relative clear-both container mx-auto mt-3`;

  const Heading = tw.h1`relative text-2xl font-bold text-center`;
  const Description = tw.h3`mt-4 text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4 justify-center`;
  const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
  const IconContainer = styled.div`
    ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
    svg {
      ${tw`w-3 h-3`}
    }
  `;
  const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

  return (
    <AnimationRevealPage>
      <Helmet>
        <title>{office.title}</title>
        <meta name="description" content={office.description} />

      </Helmet>
      <Container>
        {office && office.images && <ImageGallery items={office.images} />}
      </Container>
      <DataContainer>
        <Heading>{office.title}</Heading>
        <Description>{office.description}</Description>
        <SecondaryInfoContainer>
          <IconWithText>
            <IconContainer>
              <LocationIcon />
            </IconContainer>
            <Text>{office.place}</Text>
          </IconWithText>
          <IconWithText>
            <IconContainer>
              <PriceIcon />
            </IconContainer>
            <Text>{office.rentPrice}</Text>
          </IconWithText>
          <IconWithText>
            <IconContainer>
              <HomeIcon />
            </IconContainer>
            <Text>{office.size}m²</Text>
          </IconWithText>
        <IconWithText>
            <IconContainer>
              <LocationIcon />
            </IconContainer>
            <Text>{office.address}</Text>
          </IconWithText>
        </SecondaryInfoContainer>
      </DataContainer>
    </AnimationRevealPage>
  );
}