import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "images/deel/icon.png";

const Container = tw.div`relative bg-gray-900 text-gray-100 -mb-8 px-8 py-4`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8 px-4`
const FiveColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black`;

const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`
const CopyrightNotice = tw.div``
const CompanyInfo = tw.div``

const Divider = tw.div`my-8 border-b-2 border-gray-800`
export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <CompanyColumn>
            <LogoContainer>
              <LogoImg src={LogoImage} />
              <LogoText>DEEL</LogoText>
            </LogoContainer>
            <CompanyAddress>
              World Trade Center Ciudad de México
              Montecito 38, PB, Local 8
              Col. Nápoles, Benito Juárez
            </CompanyAddress>
            <SocialLinksContainer>
            </SocialLinksContainer>
          </CompanyColumn>
          <Column>
            <ColumnHeading>Enlaces</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/">Inicio</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/renta-oficinas">Oficinas en renta</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/venta-oficinas">Oficinas en venta</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/remodelaciones">Remodelaciones</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/acerca">Acerca de nosotros</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Contacto</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="tel:5590000090">(55) 9000-0090</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="mailto:contacto@deelbienesraices.com">contacto@deelbienesraices.com</Link>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider/>
        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice>&copy; Copyright 2020, Deel Bienes Raíces.</CopyrightNotice>
          <CompanyInfo>.</CompanyInfo>
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};
